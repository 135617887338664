import gbRoutes from '@/config/next/routes/gb.json';
import globalRoutes from '@/config/next/routes/global.json';

export const routes = {
  ...globalRoutes,
  ...gbRoutes,
  /**
   * @deprecated Use [signIn](https://git.manomano.tech/solutions/spartacux/-/blob/master/src/domains/customerManagement/auth/services/client.ts?ref_type=heads#L28) method instead.
   */
  login: gbRoutes.login,
};
